import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardPlugin from './plugins/dashboard-plugin'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PortalVue from "portal-vue"
import VueSweetalert2 from 'vue-sweetalert2';

// router setup
import router from './routes/router';

import i18n from './i18n';
import constants from '@/constants';
import store from '@/store';

Vue.use(VueAxios, axios);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);

const swalOptions = {
    customClass: "swal2-popup-dark",
    //confirmButtonColor: '#059513',
    confirmButtonColor: '#376EE2',
};
Vue.use(VueSweetalert2, swalOptions);
Vue.use(ElementUI, { locale } );
Vue.use(BootstrapVue);
Vue.use(PortalVue);

Vue.prototype.$constants = constants;

new Vue({
    el: '#app',
    render: h => h(App),
    store,
    router,
    i18n
});
