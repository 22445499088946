<template>
    <div :class="['card', 'client-country-select', !expanded ? 'collapsed' : 'expanded', internalUser ? 'internal' : '']">
        <button class="btn-link expand-button" @click="expand"><i :class="['icon-connect', 'icon-connect-collapse', !expanded ? 'icon-connect-rotate-180' : '']"></i></button>
        <div v-if="expanded" class="selects-wrapper">
            <div 
                class="client-select-wrapper"
                data-toggle="buttons">
                    <div :class="{label: true, client: true, internal: internalUser}">
                        {{ internalUser ? 'Client:' : 'Business unit:'}}
                    </div>
                    <el-tooltip :content="labelsList.join(', ')" placement="top" :disabled="!(clientsSelectValue.length > 1 || (userSelectedMultipleClients !== null && userSelectedMultipleClients.length > 0))">
                        <el-select class="client-select select-primary"
                            ref="clientsSelect"
                            multiple collapse-tags 
                            placeholder="Select Unit"
                            v-model="clientsSelectValue"
                            @change="$root.$emit('reset-route')"
                            popper-class="client-country-select-dropdown">
                            <div>
                                <el-option
                                    label="All"
                                    value="All">
                                    <span>All</span>
                                </el-option>
                                <el-option
                                    v-for="(unit, index) in filteredClientOptions"
                                    :key="index"
                                    :label="unit.label"
                                    :value="unit.value">
                                    <span>{{ unit.label }}</span>
                                </el-option>
                            </div>
                        </el-select>
                    </el-tooltip>
            </div>
           <span slot="reference" v-if="!internalUser && countriesOptions.length < 5">
                <div class="country-select" data-toggle="buttons" >
                    
                    <el-tooltip :content="option.label" placement="top" 
                            v-for="(option, index) in countriesOptions"
                            :key="index">
                        <button 
                            class="btn btn-link"
                            :class="{ active: (countryValue === option.value) }"
                            :id="index"
                            v-on:click="selectCountry(option.value)">
                            <span class="d-block d-sm-none">{{ option.label }}</span>
                            <img class="d-none d-sm-block" :src="option.image" :alt="option.label">
                        </button>
                    </el-tooltip>
                </div>
            </span>
            <div
                class="country-select-wrapper"
                data-toggle="buttons" v-else>
                <span>
                    <div class="label country">
                        Country:    
                    </div>
                    <el-select class="client-select select-primary" placeholder="Select" v-model="countryValue" @change="selectCountry" popper-class="client-country-select-dropdown">
                        <div>
                            <el-option
                                v-for="(option, index) in countriesOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value">
                                <span>{{ option.label }}</span>
                            </el-option>
                        </div>
                    </el-select>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    name: 'client-country-select',
    props: {
        clientOptions: {
            type: Array,
            default:  function () {
                return ['All']
            },
        },
        countriesOptions: {
            type: Array,
            default: function () {
                return ['All']
            },
            description: 'List of availablie Countries'
        },
        // selectedClientsList: {
        //     type: Array,
        // },
        // countryID: {
        //     type: String,
        //     default: '',
        // },
        // internalUser: {
        //     type: Boolean,
        //     default: false,
        // }
    },
    data() {
        return {
            countryValue: this.userSelectedCountry,
            clientsSelectValue: this.userSelectedMultipleClients ? this.userSelectedMultipleClients : [],
            expanded: true,
            selectShown: false,
        }
    },
    methods: {
        selectCountry(country) {
            this.resetRoute();
            this.countryValue = country;
            //this.$emit('update:countryID', country)
            this.updateUserSelectedCountry(country);
        },
        expand() {
            this.expanded = !this.expanded;
        },
        resetRoute(){
            this.$root.$emit('reset-route');
        },
        ...mapActions([
            'updateUserSelectedCountry',
            'updateUserSelectedMultipleClients'
        ])
    },
    watch: {
        userSelectedMultipleClients: {
            immediate: true,
            handler(newValue){
                if(newValue !== null && newValue !== undefined){
                    this.clientsSelectValue = newValue;
                }
                else{
                    this.clientsSelectValue = ['All'];
                }
            }
        },
        
        clientsSelectValue: {
            immediate: true,
            handler(newVal, oldVal){
                if(oldVal && oldVal.toString() !== newVal.toString()){
                    var reset = newVal[newVal.length-1] === 'All' && oldVal[0] !== 'All';
                    if(reset){
                        this.clientsSelectValue = ['All'];
                        this.updateUserSelectedMultipleClients(null);
                        //this.$emit('update:selectedClientsList', null);
                    }
                    else{
                        var filteredOptions = newVal.filter(item => item !== 'All');
                        
                        if(filteredOptions.length === 0){
                            this.updateUserSelectedMultipleClients(null);
                            //this.$emit('update:selectedClientsList', null);
                        }
                        else{
                            this.updateUserSelectedMultipleClients(filteredOptions);
                            //this.$emit('update:selectedClientsList', filteredOptions);
                        }
                    }
                }
                if(this.countryList){
                    if(this.countryList && this.countryList.length === 1){
                        this.countryValue = this.countryList[0];
                    }
                    else{
                        //this.countryValue = this.countryID;
                        this.countryValue = this.userSelectedCountry
                    }
                }
            },
        },
        userSelectedCountry: {
            immediate: true,
            handler(newValue){
                if(this.userSelectedMultipleClients !== null){
                    var possibleClients = this.userSelectedMultipleClients.filter((clientIdString) => {
                        return this.filteredClientOptions.some(
                            (client) => {
                                return client.clientID === clientIdString;
                            });
                    });
                    if(possibleClients.length > 0){
                        this.clientsSelectValue = possibleClients;
                    }
                    else{
                        this.clientsSelectValue = ['All'];
                    }
                }
                // else{
                //     console.log("selectedClientsList " + this.selectedClientsList);
                // }
                this.countryValue = newValue;
            },
        },
    },
    computed:{
        filteredClientOptions(){
            if(this.userSelectedCountry !== null){
                return this.clientOptions.filter((item) => { return item.marathonCountryID === this.userSelectedCountry});
            }
            return this.clientOptions;
        },
        labelsList(){
            return this.clientOptions.filter((item) => { return this.clientsSelectValue.includes(item.clientID) }).map(function(elem){
                return elem.label;
            });
        },
        countryList(){
            return this.clientOptions.filter((item) => { return this.clientsSelectValue.includes(item.clientID) }).map(function(elem){
                return elem.marathonCountryID;
            }).filter(function(item, pos, self) {
                return self.indexOf(item) == pos;
            });
        },
        ...mapGetters([
            'internalUser'
        ]),
        ...mapState([
            'userSelectedCountry',
            'userSelectedMultipleClients'
        ])
    }
}
</script>