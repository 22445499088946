<template>
     <label :class="[ 'label-input', 'textarea' , required ? 'required' : '', disabled ? 'disabled' : '', (value != null && value.toString().length > 0  || focused)? 'active' : '', height > 1 ? 'height-' + height : '', !valid ? 'error' : '', focused ? 'focus' : '']">
        <textarea
            :required="required"
            :disabled="disabled"
            :aria-required="required"
            :class="[ required ? 'required' : '', (value != null && value.toString().length > 0  || focused)? 'active' : '', height > 1 ? 'height-' + height : '', focused ? 'focus' : '']"
            :value="value"
            v-bind="$attrs"
            :placeholder="spacedPlaceholder"
            v-on="listeners" />
        <span>{{ label }}</span>
    </label>
</template>
<script>
export default {
    inheritAttrs: false,
    name: 'text-area',
    props: {
        label: {
            type: String
        },
        value: {
            type: [String, Object]
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 1
        },
        isValid: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false,
            valid: true,
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange
            };
        },
        spacedPlaceholder(){
            if(this.placeholder !== null){
                return this.placeholder;
            }
            else{
                return null;
            }
        }
    },
    watch: {
        isValid(){
            if(this.required){
                this.valid = this.isValid && (this.value != null && this.value.toString().length > 0);
            }
            else{
                this.valid = this.isValid;
            }
        },
        // value(newVal){
        //     console.log(newVal)
        // }
    },
    methods: {
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
      
            if(this.required){
                this.valid = this.isValid && (evt.target.value != null && evt.target.value.toString().length > 0);
            }
            else{
                this.valid = this.isValid;
            }
            
            this.$emit('update:isValid', this.isValid);
            this.$emit('input', evt.target.value);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        },
        onChange(evt) {
            this.$emit('change', evt)
        }
    },  
};
</script>