<template>
  <div class="d-flex">
    <date-input
        v-if="type=='date'"
        :required="required"
        :aria-required="required"
        :label="label"
        :value="value" 
        :disabled="disabled" 
        v-bind="$attrs"
        :isValid.sync="isValid"
        v-on="listeners"/>
    <text-input
        v-else
        ref="current"
        :type="typeCopy"
        :required="required"
        :aria-required="required"
        :disabled="disabled" 
        :label="label"
        :value="focused ? value : displayValue"
        :isValid.sync="isValid"
        v-bind="$attrs"
        v-on="listeners"/>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import TextInput from './TextInput.vue';
import DateInput from './DateInput.vue';
export default {
    name: 'form-input',
    props:  {
        required: Boolean,
        disabled: Boolean,
        label: {
            type: String,
            description: 'Input label'
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: [String, Number, Object],
            description: 'Input value'
        },
        isValid: {
            type: Boolean,
            default: true
        },
        formatter: { 
            type: Function,
        },
        parser: { 
            type: Function,
        },
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    components: {
        [TextInput.name]: TextInput,
        [DateInput.name]: DateInput,
    },
    data() {
        return {
            focused: false,
            touched: false,
            typeCopy: this.type,
            valid: true,
            displayValue: this.value,
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange
            };
        },
        haveFormatter(){
            return  this.formatter !== undefined;
        }
    },
    methods: {
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
            if(this.required){
                this.valid = this.isValid && (this.value != null && this.value.toString().length > 0);
            }
            else{
                this.valid = this.isValid;
            }
            
            this.$emit('update:isValid', this.isValid);
            this.$emit('input', evt);
        },
        onFocus(evt) {
            if(this.haveFormatter){
                this.typeCopy = this.type;
            }
            // else if(this.parser == undefined && this.type === "date"){
            //     this.typeCopy = this.type;
            //     // console.log(this.$refs.current)
            //     // console.log(this.$refs.current.$refs.input)
            //     // this.$refs.current.$refs.input.focus();

            // }
            this.focused = true; 
            this.$emit('focus', evt)
           
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
            if(this.parser !== undefined){
                this.typeCopy = 'text';
                
                var parsed = this.parser(this.displayValue)
                if(parsed && this.value !== parsed){
                    this.$emit('input', parsed);
                }
            }
            // else if(this.parser == undefined && this.type === "date"){
            //     this.typeCopy = 'text';
                
            //     var parsed = dayjs(this.displayValue).format('YYYY-DD-MM')
            //     if(parsed && this.value !== parsed){
            //         this.$emit('input', parsed);
            //     }
            // }
            else{
                this.displayValue = this.value
            }
        },
        onChange(evt) {
            this.$emit('change', evt)
        }
    },
    watch:{
        value(newVal){
            if(newVal && this.haveFormatter){
                //this.typeCopy = "text";
                this.typeCopy = 'text';
                this.displayValue = this.formatter(newVal);
            }
            // else if(!this.haveFormatter && this.type === "date"){
            //     this.typeCopy = 'text';
            //     if(this.value){
            //         this.displayValue = dayjs(this.value).format('DD/MMM/YYYY');
            //     }
            //     //this.displayValue = this.value;
        
            //     console.log(this.displayValue)
            // }
            else{
                this.displayValue = newVal;
            }
        },
    },
    mounted(){
        if(this.haveFormatter){
            this.displayValue = this.formatter(this.value);
        }
        // else if(!this.haveFormatter && this.type === "date"){
        //     if(this.value){
        //         this.displayValue = dayjs(this.value).format('DD/MMM/YYYY');
        //     }
        //     this.displayValue = this.value;
    
        //     console.log(this.displayValue)
        // }
        else{
            this.displayValue = this.value;
        }
    }
  
};
</script>
<style></style>

