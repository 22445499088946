<template>
    <footer class="footer">
        <div class="container-fluid">
            <ul class="nav">
                <li class="nav-item">
                    <router-link :to="{'name': 'Terms & Conditions'}" class="connect-footer-link">
                        Terms & Conditions
                    </router-link>
                    <!-- <a href="terms-and-conditions" rel="noopener" class="connect-footer-link">
                        Terms
                    </a> -->
                </li>
                <li class="nav-item">
                     <router-link :to="{'name':  'Privacy Policy'}" class="connect-footer-link">
                        Privacy Policy
                    </router-link>
                    <!-- <a href="privacy-policy" rel="noopener" class="connect-footer-link">
                        Privacy Policy
                    </a> -->
                </li>
                <li class="nav-item">
                    <a href="mailto:pmdkapi@publicismedia.com" rel="noopener" class="connect-footer-link">
                        Support
                    </a>
                </li>
            </ul>

            <div class="copyright">
                <span>
                    © {{ year }}
                    <a target="_blank" rel="noopener">Publicis Groupe
                    </a>

                </span>
            </div>

            <!-- <div class="skin-switch" style="margin-top:-4px">
                {{ !darkMode ? "Light skin" : "Dark skin" }}
                <b-form-checkbox switch v-model="darkModeValue" v-on:input="toggleMode"></b-form-checkbox>
            </div> -->
        </div>
    </footer>
</template>
<script>
import { BaseSwitch } from 'src/components';
import config from '@/config';

export default {
    components: {
        BaseSwitch
    },
    props: {
        darkMode: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            darkModeValue: this.darkMode,
            year: new Date().getFullYear(),
        };
    },
    methods: {
        // toggleMode(type) {
        //     let docClasses = document.body.classList;
        //     if (type) {
        //         docClasses.remove('white-content');
        //     } else {
        //         docClasses.add('white-content');
        //     }
        // },
        toggleMode(type) {
            console.log(type)
            this.$emit('update:darkMode', type)
        },
    },
    computed: {
        version(){
            return config.connectVersion
        }
    },
    // mounted(){
    //     this.toggleMode(this.darkMode)
    // }
};
</script>

