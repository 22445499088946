<template>
     <label :class="['label-input', 'date-input', required ? 'required' : '', ((valueCopy != null && valueCopy != undefined && valueCopy.toString().length > 0 && valueCopy != '') || focused) ? 'active' : '',  disabled ? 'disabled': '', !valid ? 'error' : '', focused ? 'focus' : '']">
        <div class="xmx-dateinput-opener" @click.self="openPicker()"/>
        <date-picker
            tabindex="0"
            type="date"
            ref="input"
            v-model="valueCopy"
            :required="required"
            :aria-required="required"
            :disabled="disabled"
            :class="{'required': required, 'active': ((valueCopy != null && valueCopy != undefined && valueCopy.toString().length > 0 && valueCopy != '') || focused), 'disabled': disabled, 'error' : !valid, 'focus': focused  }"
            :clearable="false"
            v-bind="$attrs"
            prefix-class="xmx"
            v-on="listeners"
            format="DD MMM YYYY"
            value-type="YYYY-MM-DD"
            :open="isOpen"
            :append-to-body="false"
            :popup-style="{ top: '100%', left: '0', zIndex: '100'}"
        />
        <span>{{ label }}</span>
    </label>
</template>
<script>
import DatePicker from 'vue2-datepicker';
export default {
    inheritAttrs: false,
    name: 'date-input',
    props: {
        required: {
            type: Boolean,
            default: true
        },
        disabled: Boolean,
        isValid: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            description: 'Input label'
        },
        type: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Object],
            description: 'Input value'
        },
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false,
            valid: true,
            valueCopy: this.value,
            isOpen: false
        };
    },
    watch: {
        value(newVal){
            this.valueCopy = newVal;
            this.validationCheck();
            this.isOpen = false;
        },
        isValid(){
            this.validationCheck();
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange,
                close: this.onClose
            };
        },
    },
    methods: {
        onClose() {
            this.validationCheck();
        },
        onInput() {
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('update:isValid', this.isValid);
            this.$emit('input', this.valueCopy);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            if (evt.relatedTarget === null) {
                this.isOpen = false;
            }
            this.validationCheck();
            this.focused = false;
            this.$emit('blur', evt);
        },
        onChange(evt) {
            this.$emit('change', evt)
        },
        validationCheck() {
            if(this.required && !this.isOpen){

                this.valid = this.isValid && (this.valueCopy != null && this.valueCopy.toString().length > 0);
            }
            else{
                this.valid = this.isValid;
            }
        },
        openPicker() {
            this.isOpen = true;
        },
    },
    components: {
        DatePicker,
    },
};
</script>