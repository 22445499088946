<template>
    <base-nav v-model="showMenu" class="connect-navbar" :transparent="false" expand="sm">
        <div class="navbar-toggle sidebar-toggle" slot="sidebar-toggle"
            :class="{ toggled: $sidebar.showSidebar }"
        >
            <button type="button" class="navbar-toggler" @click="toggleSidebar">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
            </button>
        </div>
        <div slot="brand" class="navbar-wrapper">
            <router-link tag="a" class="logo-normal" class-active="active" to="/home" exact>
                <img src="/img/nf_logo.svg" v-if="userType === 'EXTERNAL'" />
                <template v-else>
                    <img src="/img/logo-white.png" v-if="darkMode" />
                    <img src="/img/logo.png" v-else />
                </template>
            </router-link>
            <h1 class="app-title">
                {{ title }}
            </h1>
        </div>

        <ul class="navbar-nav user-menu" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <base-dropdown
                tag="li"
                :menu-on-right="!$rtl.isRTL"
                title-tag="a"
                class="nav-item"
                title-classes="nav-link"
                menu-classes="dropdown-navbar"
            >
                <template slot="title">
                    <li class="username">
                        <p>{{ username }}</p>
                        <b class="caret"></b>
                    </li>
                </template>
                <li class="nav-link">
                    <router-link :to="{'name': $constants.routeNames.user.root}" class="nav-item dropdown-item">Profile</router-link>
                </li>
                <li class="nav-link">
                    <a @click="logout" class="nav-item dropdown-item">Log out</a>
                </li>
            </base-dropdown>
        </ul>
    </base-nav>
</template>
<script>
//import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from "@/components";
//import SidebarToggleButton from './SidebarToggleButton';

export default {
    components: {
    //SidebarToggleButton,
    //CollapseTransition,
        BaseNav,
        Modal,
    },
    props: {
        darkMode: {
            type: Boolean,
            default: true,
        },
        logout: { type: Function },
        title: {
            type: String,
            default: "Publicis Connect",
            description: "Sidebar title",
        },
        username: {
            type: String,
            description: "User name",
        },
        userType: {
            type: String,
            description: "User type (internal/external)",
        },
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
        isRTL() {
            return this.$rtl.isRTL;
        },
    },
    data() {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: "",
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
    },
};
</script>
<style scoped>
.top-navbar {
    top: 0px;
}
</style>
