<template>
    <b-form-group :label="label" :id="'form-' + cId + '-group'" :label-for="'form-' + cId + '-select'"
        :class="[required ? 'required' : '', disabled ? 'disabled' : '' , value != null && value.toString().length > 0 ? 'active': '', height > 1 ? 'height-' + height : '', !valid ? 'error' : '', focused ? 'focus' : '']">
        <b-form-select :value="value" :required="required" :aria-required="required" :id="'form-' + cId + '-select'"
            :class="{'required': required, 'active': (value != null && value.toString().length > 0), 'error' : !valid, 'focus': focused }" v-bind="$attrs" v-on="listeners" :disabled="disabled">
            <slot></slot>
        </b-form-select>
    </b-form-group>
</template>
<script>
export default {
    name: 'base-select',
    props: {
        required: Boolean,
        disabled: Boolean,
        label: {
            type: String,
            description: 'Input label'
        },
        id: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Object],
            description: 'Input value'
        },
        height: {
            type: Number,
            default: 1
        },
        isValid: {
            type: Boolean,
            default: true,
        },
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false,
            typeCopy: this.type,
            cId: null,
            valid: true,
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange
            };
        },
    },
    watch: {
        isValid() {
            if (this.required) {
                this.valid = this.isValid && (this.value != null && this.value.toString().length > 0);
            } else {
                this.valid = this.isValid;
            }
        }
    },
    methods: {
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('input', evt);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            if (this.required) {
                this.valid = this.isValid && (evt.target.value != null && evt.target.value.toString().length > 0);
            } else {
                this.valid = this.isValid;
            }
            this.focused = false;
            this.$emit('update:isValid', this.isValid);
            this.$emit('blur', evt)
        },
        onChange(evt) {
            if (this.required) {
                this.valid = this.isValid && (evt != null && evt.toString().length > 0);
            } else {
                this.valid = this.isValid;
            }
            this.$emit('update:isValid', this.isValid);
            this.$emit('change', evt)
        }
    },
    mounted() {
        if (this.cId) {
            this.cId = this.id;
        } else {
            this.cId = this._uid
        }
    }

};
</script>
<style></style>

