<template>
    <card class="card-stats" :show-footer-line="true">
        <div class="row">
            <div class="col-12" v-if="$slots.icon || icon">
                <div class="info-icon text-center" :class="`icon-${type}`">

                </div>
            </div>
            <div class="col-12" v-if="$slots.content || title || subTitle">
                <div class="numbers" style="float:left">
                    <slot>
                        <h3 v-if="title" class="card-title">{{ title }}</h3>
                        <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
                    </slot>
                </div>
            </div>
        </div>
        <div class="stats" slot="footer" v-if="$slots.footer">
            <slot name="footer"></slot>
        </div>
    </card>
</template>
<script>
import Card from './Card.vue';

export default {
    name: 'stats-card',
    components: {
        Card
    },
    props: {
        type: {
            type: String,
            default: 'primary'
        },
        icon: String,
        title: String,
        subTitle: String
    }
};
</script>
<style>
.card-stats {
  transition: .3s;
}

.card-stats:hover {
  background: black;
  margin-top: -4px;
}

.card-stats .info-icon {
  float:right;
  cursor: default;
}

.card-stats .numbers .card-category {
  text-align: left;
  font-size:14pt;
  cursor: default;
}

.card-stats .numbers .card-title {
  font-weight: 600;
  text-align: left;
  margin-bottom: -4px;
  cursor: default;
  font-size: 18pt;
}
</style>
