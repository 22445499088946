import constants from '@/constants';


// Publicis Connect General
const Home = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Home/Home.vue');
const UserProfile = () => import( /* webpackChunkName: "user" */ 'src/pages/User/Profile.vue')

// Publicis Connect Features
const NewUser = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Login/NewUserModal.vue');
//const ResetPasswordModal = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Login/ResetPasswordModal.vue');
const Insights = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Insights/Insights.vue');
const InsightsOverview = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Insights/InsightsOverview.vue');
const Reporting = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Reporting/Reporting.vue');
const ReportingDetail = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Reporting/ReportingDetail.vue');
const Dashboards = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/LiveDashboards/LiveDashboards.vue');
const Briefs = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Briefs/Briefs.vue');
const BriefsOverview = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Briefs/BriefsOverview.vue');
const BriefDetail = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Briefs/BriefDetail.vue');
const BriefPreview = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Briefs/BriefDetail/BriefPreview.vue');
const BriefEdit = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Briefs/BriefDetail/BriefEdit.vue');
const Campaigns = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Campaigns/Campaigns.vue');
const CampaignsOverview = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Campaigns/CampaignsOverview.vue');
const CampaignDetail = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Campaigns/CampaignDetail.vue');
const PlanDetail = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Campaigns/PlanDetail.vue');
const Administration = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/Administration/Administration.vue');
const DashboardsCampaign = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/LiveDashboards/LiveDashboardsCampaign.vue');
const DashboardsPacing = () => import( /* webpackChunkName: "dashboard" */ 'src/pages/LiveDashboards/LiveDashboardsPacing.vue');

const TermsAndConditions = () => import( /* webpackChunkName: "user" */ 'src/pages/StaticPages/TermsAndConditions.vue');
const PrivacyPolicy = () => import( /* webpackChunkName: "user" */ 'src/pages/StaticPages/PrivacyPolicy.vue');

const routes = [{
        path: '',
        redirect: '/home'
    },
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: Home,
        children: [
            {
                path: '',
                name: constants.routeNames.home.root,
                component: CampaignsOverview,
                meta: {
                    routeGroup: 'home',
                    pageName: 'Home'
                },
            }, 
        ]
    },
    {
        path: '/briefs',
        component: Briefs,
        children: [{
                path: ':marathonCountryID/:clientID/:campaignID?/',
                component: BriefDetail,
                children: [{
                        path: 'edit/',
                        name: constants.routeNames.briefs.edit,
                        component: BriefEdit,

                        meta: {
                            routeGroup: 'briefs',
                            hideHeader: true,
                            hideCountrySelect: true,
                            pageName: 'Brief template'
                        }
                    },
                    {
                        path: '',
                        name: constants.routeNames.briefs.preview,
                        component: BriefPreview,
                        meta: {
                            routeGroup: 'briefs',
                            hideHeader: true,
                            hideCountrySelect: true,
                            pageName: 'Briefed'
                        }
                    },
                ]
            },
            {
                path: '/',
                name: constants.routeNames.briefs.root,
                component: BriefsOverview,
                meta: {
                    routeGroup: 'briefs',
                    pageName: 'Briefs'
                }
            },
        ]
    },
    {
        path: '/campaigns',
        component: Campaigns,
        children: [{
                path: ':marathonCountryID/:clientID/:campaignID/:planID',
                name: constants.routeNames.campaigns.planDetail,
                component: PlanDetail,
                props: route => ({
                    marathonCountryID: route.params.marathonCountryID,
                    campaignID: route.params.campaignID,
                    planID: route.params.planID
                }),
                meta: {
                    routeGroup: 'campaigns',
                    pageName: 'Media Plan',
                }
            },
            {
                path: ':marathonCountryID/:clientID/:campaignID',
                name: constants.routeNames.campaigns.detail,
                component: CampaignDetail,
                
                meta: {
                    routeGroup: 'campaigns',
                    pageName: 'Campaigns',
                    alternativePageName: 'Campaign media plan'
                }
            },
            {
                path: '/',
                name: constants.routeNames.campaigns.root,
                component: CampaignsOverview,
                meta: {
                    routeGroup: 'campaigns',
                    pageName: 'Campaigns',
                    alternativePageName: 'Campaign media plan'
                }
            },
        ]
    },
    {
        path: '/insights',
        component: Insights,
        children: [
            {
                path: '/',
                name: constants.routeNames.insights.root,
                component: InsightsOverview,
                meta: {
                    routeGroup: 'insights',
                    pageName: 'Insights',
                }
            }, 
        ]
    },
    {
        path: '/reporting',
        component: Reporting,
        children: [{
                path: ':marathonCountryID/:clientID/:campaignID',
                name: constants.routeNames.reporting.detail,
                component: ReportingDetail,
                meta: {
                    routeGroup: 'reporting',
                    pageName: 'Reporting',
                }
            },
            {
                path: '/',
                name: constants.routeNames.reporting.root,
                component: CampaignsOverview,
                meta: {
                    routeGroup: 'reporting',
                    pageName: 'Reporting',
                }
            },
        ]
    },
    {
        path: '/live-dashboards',
        redirect: '/liveDashboard'
    },
    {
        path: '/dashboards',
        redirect: '/liveDashboard'
    },
    {
        path: '/liveDashboards',
        redirect: '/liveDashboard'
    },
    {
        path: '/liveDashboard',
        component: Dashboards,
        children: [{
                path: ':marathonCountryID/:clientID/:campaignID/:type/:insertionOrderID',
                name: constants.routeNames.dashboards.programmatic,
                component: DashboardsPacing,
                meta: {
                    routeGroup: 'live-dashboard',
                    pageName: 'Live Dashboard',
                }
            },
            {
                path: ':marathonCountryID/:clientID/:campaignID/:type/:siteID/:dcmCampaignID',
                name: constants.routeNames.dashboards.direct,
                component: DashboardsPacing,
                meta: {
                    routeGroup: 'live-dashboard',
                    pageName: 'Live Dashboard',
                }
            },
            {
                path: ':marathonCountryID/:clientID/:campaignID',
                name: constants.routeNames.dashboards.campaign,
                component: DashboardsCampaign,
                meta: {
                    routeGroup: 'live-dashboard',
                    pageName: 'Live Dashboard',
                },
                props: route => ({
                    marathonCountryID: route.params.marathonCountryID,
                    campaignID: route.params.campaignID
                })
            },
            {
                path: '/',
                name: constants.routeNames.dashboards.root,
                component: CampaignsOverview,
                meta: {
                    routeGroup: 'live-dashboard',
                    pageName: 'Live Dashboard',
                }
            },
        ]
    },
    {
        path: '/administration',
        name: constants.routeNames.administration.root,
        components: {
            default: Administration,
        },
        meta: {
            routeGroup: 'administration',
            hideCountrySelect: true,
            pageName: 'Administration'
        },
        
    },
    {
        path: '/newUser',
        name: 'New User',
        components: {
            default: NewUser
        },
        meta: {
            routeGroup: 'user',
            pageName: 'New User',
        }
    },
    {
        path: "/user-profile",
        name: constants.routeNames.user.root,
        component: UserProfile,
        meta: {
            routeGroup: 'user',
            pageName: 'Profile',
        }
    },
    {
        path: '/resetPassword',
        name: 'Reset Password',
        meta: {
            routeGroup: 'user',
            pageName: 'Reset Password',
        }
    },
    {
        path: "/terms-and-conditions",
        name: "Terms & Conditions",
        component: TermsAndConditions,
        
        meta: {
            pageName: "Terms & Conditions",
        }
    },
    {
        path: "/privacy-policy",
        name: "Privacy Policy",
        component: PrivacyPolicy
    },
    {
        path : "*",
        redirect: "/" 
    }
];

export default routes;
