<template>
     <label class="label-input" :class="{'required': required, 'active': (value != null && value.toString().length > 0 || focused), 'disabled': disabled, 'error' : !valid, 'focus': focused}">
        <input 
            ref="input"
            :type="type"
            :required="required"
            :disabled="disabled"
            :aria-required="required"
            :class="{'required': required, 'active': (value != null && value.toString().length > 0 || focused), 'disabled': disabled, 'error' : !valid,  'focus': focused }"
            :value="value"
            v-bind="$attrs"
            v-on="listeners">
            <span>{{ label }}</span>
    </label>
</template>
<script>
export default {
    inheritAttrs: false,
    name: 'text-input',
    props: {
        required: {
            type: Boolean,
            default: true
        },
        disabled: Boolean,
        isValid: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            description: 'Input label'
        },
        type: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Object],
            description: 'Input value'
        },
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false,
            valid: true
        };
    },
    watch: {
        isValid(){
            if(this.required){
                this.valid = this.isValid && (this.value != null && this.value.toString().length > 0);
            }
            else{
                this.valid = this.isValid;
            }
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange,
            };
        },
    },
    methods: {
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
      
            if(this.required){
                this.valid = this.isValid && (evt.target.value != null && evt.target.value.toString().length > 0);
            }
            else{
                this.valid = this.isValid;
            }
            this.$emit('update:isValid', this.isValid);
            this.$emit('input', evt.target.value);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        },
        onChange(evt) {
            this.$emit('change', evt)
        }
    },  
};
</script>