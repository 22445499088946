import Vue from 'vue'
import Vuex from 'vuex'
import constants from '@/constants'

Vue.use(Vuex);

function initState() {
    return {
        clients: null,
        users: null,
        groups: null,
        dataModels: null,
        userContent: null,
        contentResources: null,
        availableContentResources: null,
        campaignName: "",
        detailName: "",
        isLoadingClients: false,
        isLoadingUsers: false,
        isLoadingGroups: false,
        isLoadingDataModels: false,
        isLoadingContentResources: false,
        //userContent: null,
        userType: null,
        isLoadingUserContent: false,
        userSelectedMultipleClients: null,
        selectedClient: null,
        userSelectedCountry: null,
        userMarket: null,

    }
}
const store = new Vuex.Store({
    state: () => (initState()),
    actions: {
        handleRequestError(error) {
            console.error(error);
        },
        fetchClients ({commit, dispatch}, axios) {
            commit('setIsLoadingClients', true);
            const path = constants.apiPaths.planning.clients();
            return axios.get(path)
                .then((res) => {
                    if (res.data) {
                        commit('setIsLoadingClients', false);
                        return res.data;
                    }
                })
                .catch((error) => {
                    dispatch('handleRequestError', error);
                    commit('setIsLoadingClients', false);
                    return null;
                });
        },
        getClients ({commit, dispatch}, payload) {
            const axios = payload[0];
            const force = payload[1];
            if((this.state.clients === null || force) && !this.state.isLoadingClients){ 
                dispatch('fetchClients', axios)
                    .then((clients) => {
                        if (clients) {
                            commit('setClients', clients);
                            return this.state.clients;
                        }
                    })
                    .catch(() => {
                        return null;
                    });
            }
            else{
                return this.state.clients;
            }
        },
        getClient ({commit, dispatch}, payload) {
            const axios = payload[0];
            const force = payload[1];
            const clientID = payload[2];
            const marathonCountryID = payload[3];
            if((this.state.clients === null || force)){
                dispatch('fetchClients', axios)
                    .then((clients) => {
                        if (clients) {
                            commit('setClients', clients);
                            let clientsFilter = this.state.clients.filter((client) => {
                                return client.clientID === clientID && client.marathonCountryID === marathonCountryID;
                            });
                            if (clientsFilter && clientsFilter.length > 0) {
                                return clientsFilter[0] || {};
                            }
                        }
                    })
                    .catch(() => {
                        return null;
                    });
            }
            else{
                return this.state.clients.filter((client) => {
                    return client.clientID === clientID && client.marathonCountryID === marathonCountryID;
                });
            }
        },
        getUsers ({commit, dispatch}, payload) {
            const axios = payload[0];
            const force = payload[1];
            if((this.state.users === null || force) && !this.state.isLoadingUsers){
                commit('setIsLoadingUsers', true);
                const path = constants.apiPaths.administration.users;
                axios.get(path)
                    .then((res) => {
                        if (res.data && res.data.users) {
                            commit('setUsers', res.data.users);
                            commit('setIsLoadingUsers', false);
                            return this.state.users;
                        }
                    })
                    .catch((error) => {
                        dispatch('handleRequestError', error);
                        commit('setIsLoadingUsers', false);
                    });
            }
            else{
                return this.state.users;
            }

            
        }, 
        getGroups ({commit, dispatch}, payload) {
            const axios = payload[0];
            const force = payload[1];
            if((this.state.groups === null || force) && !this.state.isLoadingGroups){
                commit('setIsLoadingGroups', true);
                const path = constants.apiPaths.administration.groups;
                axios.get(path)
                    .then((res) => {
                        if (res.data && res.data.groups) {
                            commit('setGroups', res.data.groups);
                            commit('setIsLoadingGroups', false);
                            return this.state.groups;
                        }
                    })
                    .catch((error) => {
                        dispatch('handleRequestError', error);
                        commit('setIsLoadingGroups', false);
                    });
            }
            else{
                return this.state.groups;
            }
            
        },
        getDataModels ({commit, dispatch}, payload) {
            const axios = payload[0];
            const force = payload[1];
            if((this.state.dataModels === null || force) && !this.state.isLoadingDataModels){
                commit('setIsLoadingDataModels', true);
                const path = constants.apiPaths.dataModels;
                axios.get(path)
                    .then((res) => {
                        if (res.data) {
                            commit('setDataModels', res.data);
                            commit('setIsLoadingDataModels', false);
                            return this.state.dataModels;
                        }
                    })
                    .catch((error) => {
                        dispatch('handleRequestError', error);
                        commit('setIsLoadingDataModels', false);
                    });
            }
            else{
                return this.state.dataModels;
            }
            
        },
        getUserContent ({commit, dispatch}, payload) {
            const axios = payload[0];
            const force = payload[1];
            if((this.state.userContent === null || force) && !this.state.isLoadingUserContent){
                commit('setIsLoadingUserContent', true);
                const path = constants.apiPaths.user.content;
                axios.get(path)
                    .then((res) => {
                        if (res.data) {
                            if(res.data.userContent){
                                commit('setUserContent', res.data.userContent);
                            } 
                            if(res.data.userType){
                                commit('setUserType', res.data.userType);
                            }
                            if(res.data.userMarket){
                                commit('setUserMarket', res.data.userMarket);
                                commit('setUserSelectedCountry', "PM" + res.data.userMarket);
                            }
                            commit('setIsLoadingUserContent', false);
                            return this.state.userContent;
                        }
                    })
                    .catch((error) => {
                        dispatch('handleRequestError', error);
                        commit('setIsLoadingUserContent', false);
                    });
            }
            else{
                return this.state.userContent;
            }
            
        },
        getContentResources ({commit, dispatch}, payload) {
            const axios = payload[0];
            const force = payload[1];
            if((this.state.contentResources === null || force) && !this.state.isLoadingContentResources){
                commit('setIsLoadingContentResources', true);
                const path = constants.apiPaths.contentResources;
                axios.get(path)
                    .then((res) => {
                        if (res.data && res.data.contentResources) {
                            commit('setContentResources', res.data.contentResources);
                        }
                        if (res.data && res.data.availableContentResources) {
                            commit('setAvailableContentResources', res.data.availableContentResources);
                        }
                        commit('setIsLoadingContentResources', false);
                        return this.state.contentResources;
                    })
                    .catch((error) => {
                        dispatch('handleRequestError', error);
                        commit('setIsLoadingContentResources', false);
                    });
            }
            else{
                return this.state.contentResources;
            }
            
        },
        updateCampaignName ({commit}, name) {
            commit('setCampaignName', name);
        },
        updateDetailName ({commit}, name) {
            commit('setDetailName', name);
        },
        clearCampaignName ({commit}) {
            commit('setCampaignName', "");
        },
        clearDetailName ({commit}) {
            commit('setDetailName', "");
        },
        updateUserContent({commit}, content) {
            commit('setUserContent', content);
        },
        updateSelectedClient({commit, dispatch}, payload) {
            if(payload){
                const clientToUpdateTo = payload["client"];
                const axios = payload["axios"];
                if(clientToUpdateTo && typeof clientToUpdateTo === "object"){
                    if(this.state.clients !== null){
                        let clientsFilter = this.state.clients.filter((client) => {
                            return client.clientID === clientToUpdateTo.clientID && client.marathonCountryID === clientToUpdateTo.marathonCountryID;
                        });
                        if (clientsFilter && clientsFilter.length === 1) {
                            const fullClientObject = clientsFilter[0] || {}
                            commit('setSelectedClient', fullClientObject);
                            return fullClientObject;
                        }
                    }
                    else if((this.state.clients === null && axios !== null && axios !== undefined)){
                        dispatch('fetchClients', axios)
                            .then((clients) => {
                                if (clients) {
                                    commit('setClients', clients);
                                    let clientsFilter = this.state.clients.filter((client) => {
                                        return client.clientID === clientToUpdateTo.clientID && client.marathonCountryID === clientToUpdateTo.marathonCountryID;
                                    });
                                    if (clientsFilter && clientsFilter.length === 1) {
                                        const fullClientObject = clientsFilter[0] || {}
                                        commit('setSelectedClient', fullClientObject);
                                        return fullClientObject;
                                    }
                                }
                            })
                            .catch(() => {
                                return null;
                            });
                    }
                    else{
                        return null;
                    }
                }
                else{
                    commit('setSelectedClient', null);
                    //console.warn("could not update selected client, no data");
                }
            }
            else{
                commit('setSelectedClient', null);
            }
            
        },
        updateUserSelectedCountry({commit}, country) {
            commit('setUserSelectedCountry', country);
        },
        updateUserSelectedMultipleClients({commit}, clientsList) {
            commit('setUserSelectedMultipleClients', clientsList);
        },
        // updateUserSelectedClient({commit}, clientsList) {
        //     commit('setUserSelectedClient', clientsList);
        // },
        resetStore({commit}) {
            commit('clearState');
        },
    },
    mutations: {
        setClients (state, clients) {
            state.clients = clients;
        },
        setUsers (state, users) {
            state.users = users;
        },
        setGroups (state, groups) {
            state.groups = groups;
        },
        setDataModels (state, dataModels) {
            state.dataModels = dataModels;
        },
        setUserContent (state, userContent) {
            state.userContent = userContent;
        },
        setContentResources (state, contentResources) {
            state.contentResources = contentResources;
        },
        setAvailableContentResources (state, availableContentResources) {
            state.availableContentResources = availableContentResources;
        },
        setIsLoadingClients (state, isLoadingClients) {
            state.isLoadingClients = isLoadingClients;
        },
        setIsLoadingUsers (state, isLoadingUsers) {
            state.isLoadingUsers = isLoadingUsers;
        },
        setIsLoadingGroups (state, isLoadingGroups) {
            state.isLoadingGroups = isLoadingGroups;
        },
        setIsLoadingDataModels (state, isLoadingDataModels) {
            state.isLoadingdataModels = isLoadingDataModels;
        },
        setIsLoadingContentResources (state, isLoadingContentResources) {
            state.isLoadingContentResources = isLoadingContentResources;
        },
        setCampaignName (state, campaignName) {
            state.campaignName = campaignName;
        },
        setDetailName (state, detailName) {
            state.detailName = detailName;
        },
        setUserType (state, userType) {
            state.userType = userType;
        },
        setIsLoadingUserContent (state, isLoadingUserContent) {
            state.isLoadingUserContent = isLoadingUserContent;
        },
        setSelectedClient (state, client) {
            state.selectedClient = client;
        },
        setUserSelectedCountry (state, country) {
            state.userSelectedCountry = country;
        },
        setUserSelectedMultipleClients (state, clientsList) {
            state.userSelectedMultipleClients = clientsList;
        },
        setUserMarket (state, userMarket) {
            state.userMarket = userMarket;
        },
        clearState (state) {
            Object.assign(state, initState())
        }


        
    },
    getters:{
        //TODO if not set go and fetch value
        internalUser: state => {
            return state.userType ? state.userType === "INTERNAL" : null
        },
        userSelectedClient: state => {
            if(state.userSelectedMultipleClients === null){
                return null;
            }
            else{
                if(state.userSelectedMultipleClients && state.userSelectedMultipleClients.length === 1){
                    if(state.clients !== null){
                        var clients = state.clients.filter((item) => { return item.clientID === state.userSelectedMultipleClients[0]})
                        if(clients.length === 1 ){
                            return clients[0];
                        }
                    }
                }
                else{
                    return null;
                }
            }
        }
        // menuItems: state => {
        //     if(state.userContent){
        //         var resourceArray = new Array(Object.keys(state.userContent).length)
        //         for (var key in state.userContent) {
        //             resourceArray[state.userContent[key].order] = state.userContent[key];
        //         }
        //         return resourceArray;
        //     }
        //     return [];
        // },
    }
});

export default store;