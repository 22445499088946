<template>
    <!-- <b-form-group :label="label" :id="'form-' + cId + '-group'" :label-for="'form-' + cId + '-select'" :class="[required ? 'required':'' , value != null && value.toString().length > 0 ? 'active': '', height > 1 ? 'height-' + height : '', !valid ? 'error' : '']" >
        <b-form-select :value="value" :required="required" :aria-required="required" :id="'form-' + cId + '-select'" :class="{'required': required, 'active': (value != null && value.toString().length > 0), 'error' : !valid }"
          v-bind="$attrs"
          v-on="listeners">           
            <slot></slot>
        </b-form-select>
    </b-form-group> -->

    <div :class="['multiselect', required ? 'required':'' , value != null && value.toString().length > 0 ? 'active': '', height > 1 ? 'height-' + height : '', !valid ? 'error' : '', disabled ? 'disabled' : '', focused ? 'focus' : '']">
        
        <label class="label">{{ label }}</label>
        <el-select multiple :value="value" :required="required" :aria-required="required" :id="'form-' + cId + '-select'" 
                v-bind="$attrs" v-on="listeners" :disabled="disabled"
                :class="['select-primary custom-select multiselect' , required ? 'required':'' , value != null && value.toString().length > 0 ? 'active': '', height > 1 ? 'height-' + height : '', !valid ? 'error' : '', focused ? 'focus' : '']"
            popper-class="client-country-select-dropdown">
            <div slot-scope="props">
                <slot v-if="hasSlotData"></slot>
                <div v-else>
                    <el-option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                    </el-option>
                </div>
            </div>
        </el-select>
    </div>
    
</template>
<script>
export default {
    name: 'base-multiselect',
    props: {
        required: Boolean,
        disabled: Boolean,
        label: {
            type: String,
            description: 'Input label'
        },
        id: {
            type: String,
            default: ''
        },
        value: {
            type: [Array, String],
            description: 'Input value'
        },
        height: {
            type: Number,
            default: 1
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array,
            default: function(){ return []}
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false,
            typeCopy: this.type,
            cId: null,
            valid: true,
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange
            };
        },
        hasSlotData(){
            return this.$slots.default;
        },
    },
    watch: {
        isValid() {
            if (this.required) {
                this.valid = this.isValid && (this.value != null && this.value.length > 0);
            } else {
                this.valid = this.isValid;
            }
        }
    },
    methods: {
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('input', evt);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        },
        onChange(evt) {
            if (this.required) {
                this.valid = this.isValid && (evt != null && evt.length > 0);
            } else {
                this.valid = this.isValid;
            }
            this.$emit('update:isValid', this.isValid);
            this.$emit('change', evt)
        }
    },
    mounted() {
        if (this.cId) {
            this.cId = this.id;
        } else {
            this.cId = this._uid
        }
    }

};
</script>
<style></style>

