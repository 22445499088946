<template>
  <li class="breadcrumb-item" :class="{ active: active }">
    <div class="breadcrumb-content">
      <i class="btn-success btn-link tim-icons icon-double-right breadcrumb-divider" v-if="!isFirst"></i>
      <span class="breadcrumb-link">
        <slot></slot>
      </span>
    </div>
  </li>
</template>
<script>
export default {
    name: 'breadcrumb-item',
    props: {
        active: {
            type: Boolean,
            default: false,
            description: 'Whether breadcrumb item is active'
        },
        isFirst: {
            type: Boolean,
            default: false,
            description: 'Whether breadcrumb item have arrow on left'
        }
    }
};
</script>
<style></style>
