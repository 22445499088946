import config from '@/config.js'

const apiVersion = config.apiVersion;
const fallbackApiVersion = "v1.0";

export default {
    apiPaths: {
        //login: fallbackApiVersion + '/login',
        login: apiVersion + '/login',
        user: {
            content: fallbackApiVersion + '/status',
            resetPassword: fallbackApiVersion + '/resetPassword',
            changePassword: apiVersion +  '/change_password'
        },
        dataModels: fallbackApiVersion + '/dataModels',
        boxFolder: fallbackApiVersion + '/boxFolder',
        movieInfo: function(imdb_page_id) {
            return apiVersion +  `/movie_information/${imdb_page_id}`;
        },
        comments: function(type, marathonCountryID, clientID, campaignID) { 
            if(type && marathonCountryID && clientID && campaignID){
                return apiVersion +  `/comments/${type}/${marathonCountryID}/${clientID}/${campaignID}`;
            }
            return apiVersion +  '/comments';
        },
        emails: {
            invitation: apiVersion +  '/emails/invitation',
            passwordReset: apiVersion +  '/emails/password_reset',
            forgotPassword: apiVersion +  '/emails/forgot_password',
            sendNotificationEmail: fallbackApiVersion + '/sendNotificationEmail',
        },        
        notifications: {
            recipients: apiVersion +  '/notifications/recipients',
            recipientsGroups: apiVersion +  '/notifications/recipients-groups',
            notify: apiVersion +  '/notifications/notify',
        },
        administration: {
            clients: fallbackApiVersion + '/administration/clients',
            users: fallbackApiVersion + '/administration/users',
            entities: fallbackApiVersion + '/administration/entities',
            groups: fallbackApiVersion + '/administration/groups',
        },
        contentResources: fallbackApiVersion + '/contentResources',
        planning: {
            //clientsV1: fallbackApiVersion + '/planning/clients',
            // briefsV1: () => {return (fallbackApiVersion + `/planning/campaigns?brief=True`)},
            // campaignsV1: () => {return (fallbackApiVersion + `/planning/campaigns`)},
            // briefV1: () => {return (fallbackApiVersion + `/planning/campaigns`)},
            // plansV1: fallbackApiVersion + '/planning/plans',
            // briefs: function(marathonCountryID, clientID) {
            //     if(marathonCountryID && clientID){
            //         return apiVersion +  `/planning/briefs/${marathonCountryID}/${clientID}`
            //     }
            //     return apiVersion +  `/planning/briefs`
            // },
            // brief: function(marathonCountryID, clientID, campaignID) {
            //     if(marathonCountryID && clientID && campaignID){
            //         return apiVersion +  `/planning/brief/${marathonCountryID}/${clientID}/${campaignID}`
            //     }
            //     return apiVersion +  `/planning/brief`
            // },
            clients: function() {
                return apiVersion +  `/planning/clients`
            },
            campaigns: function(marathonCountryID, clientID) {
                if(marathonCountryID && clientID){
                    return apiVersion +  `/planning/campaigns/${marathonCountryID}/${clientID}`
                }
                if(marathonCountryID){
                    return apiVersion +  `/planning/campaigns/${marathonCountryID}`
                }
                return apiVersion +  `/planning/campaigns`
            },
            campaign: function(marathonCountryID, clientID, campaignID) {
                if(marathonCountryID && clientID && campaignID){
                    return apiVersion +  `/planning/campaign/${marathonCountryID}/${clientID}/${campaignID}`
                }
                return apiVersion +  `/planning/campaign`
            },
            plans: function(marathonCountryID, clientID, campaignID) {
                if(marathonCountryID && clientID && campaignID){
                    return apiVersion +  `/planning/plans/${marathonCountryID}/${clientID}/${campaignID}`
                }
                else if(marathonCountryID && clientID){
                    return apiVersion +  `/planning/plans/${marathonCountryID}/${clientID}`
                }
                return apiVersion +  `/planning/plans/`
            },
            plan: function(marathonCountryID, clientID, planID) { 
                if(planID && marathonCountryID && marathonCountryID){
                    return apiVersion +  `/planning/plan/${marathonCountryID}/${clientID}/${planID}`;
                }
                return apiVersion +  `/planning/plan/`;
            },
            orders: function(marathonCountryID, clientID, campaignID, planID) {
                if(marathonCountryID && clientID && campaignID && planID){
                    return apiVersion +  `/planning/orders/${marathonCountryID}/${clientID}/${campaignID}/${planID}`
                }
                else if(marathonCountryID && clientID && campaignID){
                    return apiVersion +  `/planning/orders/${marathonCountryID}/${clientID}/${campaignID}`
                }
                else if(marathonCountryID && clientID){
                    return apiVersion +  `/planning/orders/${marathonCountryID}/${clientID}`
                }
                return apiVersion +  `/planning/orders`
            },
            expressOrder: function(marathonCountryID, clientID, orderID) {
                if(orderID && clientID && marathonCountryID){
                    return apiVersion +  `/planning/express-order/${marathonCountryID}/${clientID}/${orderID}`;
                }
                return apiVersion +  `/planning/express-order`;
            },
            //orderInformationV1: fallbackApiVersion + '/orderInformation',
            orderInformation: function(marathonCountryID) { 
                if(marathonCountryID){
                    return apiVersion +  `/planning/order-information/${marathonCountryID}`
                }
                return apiVersion +  `/planning/order-information`
            },
            surcharges: function(marathonCountryID) {
                if(marathonCountryID){
                    return apiVersion +  `/planning/surcharges/${marathonCountryID}`
                }
                return apiVersion +  `/planning/surcharges`
            },
            media: function(marathonCountryID) {
                if(marathonCountryID){
                    return apiVersion +  `/planning/media/${marathonCountryID}`
                }
                return apiVersion +  `/planning/media`
            },
            surcharge: function(marathonCountryID, surchargeID) {
                if(marathonCountryID && surchargeID){
                    return apiVersion +  `/planning/surcharges/${marathonCountryID}/${surchargeID}`
                }
                return null
            },
            medium: function(marathonCountryID, mediumID) {
                if(marathonCountryID && mediumID){
                    return apiVersion +  `/planning/media/${marathonCountryID}/${mediumID}`
                }
                return null
            },
            // surcharges: apiVersion +  '/surcharges',
            // media: apiVersion +  '/medias',
            campaignFolder: fallbackApiVersion + '/campaignFolder',
            planVersions: fallbackApiVersion + '/planVersions',
            //products: fallbackApiVersion + '/planning/products',
            product: apiVersion + '/planning/product',
            exportPlanToExcel: function(marathonCountryID, clientID, campaignID, planID) {
                return apiVersion + `/export/excel/plan/${marathonCountryID}/${clientID}/${campaignID}/${planID}`;
            },
            exportCampaignToExcel:  function(marathonCountryID, clientID, campaignID) {
                return apiVersion + `/export/excel/campaign/${marathonCountryID}/${clientID}/${campaignID}`;
            },
            exportBriefToExcel:  function(marathonCountryID, clientID, campaignID) {
                return apiVersion + `/export/excel/brief/${marathonCountryID}/${clientID}/${campaignID}`;
            },
            calculateAmount: fallbackApiVersion + '/planning/calculateAmount',
        },
        liveDashboardV2: {
            campaign:  function(marathonCountryID, clientID, campaignID) { return apiVersion +  `/dashboard/${marathonCountryID}/${clientID}/${campaignID}`},
            programmatic: function(insertionOrderID) { return apiVersion +  `/dashboard/programmatic/${insertionOrderID}`},
            direct: function(siteID, dcmCampaignID) { return apiVersion +  `/dashboard/direct/${siteID}/${dcmCampaignID}`},
        },
        insights: apiVersion +  '/insights',
        reporting: function(marathonCountryID, clientID, campaignID)  { return apiVersion +  `/reporting/${marathonCountryID}/${clientID}/${campaignID}`},
    },
    ganttTableDayWidth: 2,
    simpleGanttTableDayWidth: 3,
    fixedColumns: {
        arrows: {
            width: 50
        },
        status: {
            prop: 'status',
            label: 'Status',
            minWidth: 40,
            width: 55
        },
        id: {
            width: 70
        },
        edit: {
            width: 55
        }
    },
    pacingTypes: {
        DIRECT: 'direct',
        PROGRAMMATIC: 'programmatic'
    },
    campaignStates: {
        BRIEF_IN_PROGRESS: {
            value: 'BRIEF_IN_PROGRESS',
            label: 'Brief in progress'
        },
        BRIEFED: {
            value: 'BRIEFED',
            label: 'Briefed'
        },
        PLAN_IN_PROGRESS: {
            value: 'PLAN_IN_PROGRESS',
            label: 'Plan in progress'
        },
        APPROVED: {
            value: 'APPROVED',
            label: 'Approved'
        },
        LIVE: {
            value: 'LIVE',
            label: 'Live'
        },
        FINISHED: {
            value: 'FINISHED',
            label: 'Finished'
        },
        CANCELLED: {
            value: 'CANCELLED',
            label: 'Cancelled'
        },
    // NOT_DEFINED: {value: 'NOT_DEFINED', label: 'Not defined'},
    },
    routeNames: {
        home: {
            root: 'Home',
        },
        briefs: {
            root: 'Briefs',
            new: 'New Brief',
            edit: 'Brief Template',
            preview: 'Brief Template Preview',

        },
        campaigns: {
            root: 'Campaigns',
            detail: 'Campaign Detail',
            planDetail: 'Plan Detail'
        },
        dashboards: {
            root: 'Live dashboards',
            campaign: 'Live dashboards - Campaign Detail',
            programmatic: 'Live dashboards - Programmatic Pacing Detail',
            direct: 'Live dashboards - Direct buys Pacing Detail',
        },
        insights: {
            root: 'Insights',
        },
        reporting: {
            root: 'Reporting',
            detail: 'Reporting Detail',
        },
        administration: {
            root: 'Administration'
        },
        user: {
            root: 'User Profile'
        },

    },
    nordiskFilmUnits: {
        homeEntertaiment: [{
            marathonCountryID: "PMDK",
            clientID: "NOHE"
        }, {
            marathonCountryID: "PMSE",
            clientID: "NFHE"
        }, {
            marathonCountryID: "PMNO",
            clientID: "NORH"
        }],
        theatrical: [{
            marathonCountryID: "PMDK",
            clientID: "FILM"
        }, {
            marathonCountryID: "PMSE",
            clientID: "NFTH"
        }, {
            marathonCountryID: "PMNO",
            clientID: "NFIL"
        }]
    },
    optionsDictionary: {
        clicks: 'Clicks',
        completeViewsVideo: 'Complete Views Video',
        completedViews: 'Completed Views',
        cpa: 'CPA',
        cpc: 'CPC',
        cpm: 'CPM',
        cpv: 'CPV',
        ctr: 'CTR',
        impressions: 'Impressions',
        spend: 'Spend',
        totalConversions: 'Total Conversions',
        viewability: 'Viewability',
        viewableImpressions: 'Viewable Impressions',
        vtr: 'VTR',
    }
};
