<template>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
        <notifications></notifications>

        <zoom-center-transition :duration="500" mode="out-in">
            <dashboard-navbar v-if="authenticated" :logout='logout' :username="username" :dark-mode="darkMode"
                :title="userType === 'EXTERNAL' ? 'Nordisk Film Connect' : 'Publicis Connect'" :userType="userType" :show-menu="false"></dashboard-navbar>
        </zoom-center-transition>
        
        <slide-y-down-transition :duration="500" mode="out-in">
            <sidebar-fixed-toggle-button />
        </slide-y-down-transition>

        <slide-y-down-transition :duration="500" mode="out-in">
            <sidebar v-if="authenticated" background-color="primary" :short-title="$t('sidebar.shortTitle')" :title="$t('sidebar.title')">
                <template slot="links" v-if="menuItems && menuItems.length > 0">
                    <sidebar-item v-for="menuItem in menuItems" :key="menuItem.order"
                        :link='{
                            name: menuItem.pageTitle,
                            icon: menuItem.frontendClass,
                            path: menuItem.endpoint }'
                            >
                    </sidebar-item>
                </template>
            </sidebar>
        </slide-y-down-transition>

        <div :class="['main-panel', $router.currentRoute.meta.hideHeader ? 'without-page-header': '']" v-if="authenticated" ref="main-content">

            <div class="row page-header" v-if="!$router.currentRoute.meta.hideHeader">
                <div class="col">
                    <div class="row first-row">
                        <div class="col d-flex justify-content-between align-items-start">
                            <div class="page-title-wrapper">
                                <h1
                                    class="page-title"
                                >
                                    {{ !internalUser && $router.currentRoute.meta.alternativePageName ? $router.currentRoute.meta.alternativePageName : $router.currentRoute.meta.pageName }}
                                </h1>
                                <h1 class="campaigns-title page-subtitle">
                                    {{ campaignName && campaignName.length > 0 ? " | " + campaignName : "" }}
                                    {{ detailName && detailName.length > 0 ? " | " + detailName : "" }}
                                </h1>
                                <portal-target name="page-title_action" class="page-title_action" ></portal-target>
                            </div>
                            <client-country-select
                                v-if="clientSelectOptions.length > 1
                                && !$router.currentRoute.meta.hideCountrySelect"
                                :countriesOptions="countriesOptions"
                                :clientOptions="clientSelectOptions"
                                :internalUser="internalUser"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div
                                @click="goBack"
                                class="back-button"
                                v-show="!isRootRoute"
                            >
                                <span class="btn btn-link btn-back"
                                    ><i class="icon tim-icons icon-double-left" aria-hidden="true"></i>
                                    <div class="label">Back</div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'content': !$route.meta.hideContent }" v-on:click="toggleSidebar">
                    
                <zoom-center-transition :duration="200" mode="out-in">
                    <router-view class="page" v-if="authenticated" :userContent="userContent" :clientSelectOptions="clientSelectOptions" :userClients="clients"  />
                </zoom-center-transition>
                <zoom-center-transition :duration="500" mode="out-in">
                    <content-footer v-if="authenticated" :dark-mode.sync="darkMode"></content-footer>
                </zoom-center-transition>
            </div>
          

           
        </div>

        <zoom-center-transition :duration="500" mode="out-in">
            <AuthModal v-on:loginSuccess="loginSuccess" :authModalVisible="authModalVisible" :sydbankVisual="sydbankDomain"
                :closeSelf=false />
        </zoom-center-transition>

        <Loader :isVisible="loadingItems > 0" text="Loading ..."/>
    </div>
</template>
<script>
import DashboardNavbar from '@/pages/Layout/DashboardNavbar.vue';
import ContentFooter from '@/pages/Layout/ContentFooter.vue';
import DashboardContent from '@/pages/Layout/Content.vue';
import SidebarFixedToggleButton from '@/pages/Layout/SidebarFixedToggleButton.vue';
import {
    SlideYUpTransition,
    SlideYDownTransition,
    ZoomCenterTransition
} from 'vue2-transitions';

import config from '@/config';
import AuthModal from '@/pages/Login/AuthModal.vue';
import ClientCountrySelect from "@/components/ClientCountrySelect/ClientCountrySelect.vue";
import Loader from '@/pages/Layout/Loader.vue'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
        SidebarFixedToggleButton,
        DashboardContent,
        SlideYDownTransition,
        ZoomCenterTransition,
        SlideYUpTransition,
        AuthModal,
        [ClientCountrySelect.name]: ClientCountrySelect,
        Loader,
    },
    data() {
        return {
            darkMode: true,
            isRootRoute: true,
            user: "",
            username: "",
            userID: "",
            authenticated: false,
            showContent: false,
            showLogin: true,
            resetPassword: false,
            authToken: '',
            authModalVisible: false,
            modelValidations: {
                user: {
                    required: true,
                    min: 4
                },
                password: {
                    required: true,
                    min: 4
                }
            },
            loadingItems: 0,
            loadingBlacklist: [
                this.$constants.apiPaths.planning.campaignFolder,
                this.$constants.apiPaths.boxFolder
            ],
            loaderEnabled: true,
            sydbankDomain: false,
            
        };
    },
    provide() {
        return {
            countriesOptions: this.countriesOptions,
            formatLargeNumber(number){
                if(typeof number === 'number'){
                    if((number / 1000000) > 1){
                        return new Intl.NumberFormat(config.locale, { maximumFractionDigits: 0 }).format(number / 1000000) + "Mil."
                    }
                    if((number / 1000) > 1){
                        return new Intl.NumberFormat(config.locale, { maximumFractionDigits: 0 }).format(number / 1000) + "K"
                    }
                    else{
                        return new Intl.NumberFormat(config.locale, { maximumFractionDigits: 0 }).format(number);
                    }
                }
                return number;
            },
            formatFullNumber(number, round = 3 ){
                if(typeof number === 'number'){
                    return new Intl.NumberFormat(config.locale, { maximumFractionDigits: round }).format(number);
                }
                return number;
            },
            handleRequestError(error) {
                console.error(error);
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
            },
            currencyFormatter(val, round = 0, currency, withUnit = false) {
                if(currency == undefined){
                    currency = "DKK"
                }
                if(val){
                    var value = parseFloat(val);
                    if (value === "NaN") {
                        value = 0;
                    }
                    
                    var formatter = new Intl.NumberFormat(config.locale, {
                        style: 'currency',
                        currency: currency,
                        //minimumFractionDigits: round,
                        maximumFractionDigits: round,
                        currencyDisplay: 'code',
                    });
                    val = formatter.format(value);
                    if(!withUnit){
                        val = val.slice(0,-4);
                    }
                }
                return val;
            },
            currencyParser(val){
                if(val && val.length > 0){
                    return val.replace(/[ ,.]/g, "");
                }
                else {
                    return val;
                }
            },
            // formatCampaignNumbers() {
            //     for (let i = 0; i < this.campaigns.length; i++) {
            //         var net = this.campaigns[i]["net"]
            //         var maxCTC = this.campaigns[i]["maxCTC"]
            //         var ctc = this.campaigns[i]["ctc"]
            //         var currency = this.campaigns[i]["currency"]
            //         var numbers = [{label:"net", value:net}, {label:"maxCTC", value:maxCTC}, {label:"ctc", value:ctc}]

            //         for (var j = 0; j < numbers.length; j++) {
            //             if (numbers[j].value) {
            //                 numbers[j].value = numbers[j].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            //                 if (parseInt(numbers[j].value) > 0) {
            //                     this.campaigns[i][numbers[j].label] = numbers[j].value + " " + currency
            //                 }
            //             } else {
            //                 numbers[j].value = 0;
            //             }
            //         }
            //     }
            // },
            formatCampaignNumbers(campaigns) {
                for (let i = 0; i < campaigns.length; i++) {
                    var net = campaigns[i]["net"]
                    var maxCTC = campaigns[i]["maxCTC"]
                    var ctc = campaigns[i]["ctc"]
                    var currency = campaigns[i]["currency"]
                    if(currency == "" || currency === null || currency === undefined){
                        currency = campaigns[i]["campaignCurrency"]
                    }
                    if(currency == "" || currency === null || currency === undefined){
                        currency = 'DKK'
                    }
                    //var currency = campaigns[i]["campaignCurrency"]
                    var numbers = [{label:"net", value:net}, {label:"maxCTC", value:maxCTC}, {label:"ctc", value:ctc}]

                    for (var j = 0; j < numbers.length; j++) {
                        if (numbers[j].value) {
                            var value = parseFloat(numbers[j].value);
                            if (value === "NaN") {
                                value = 0;
                            }
                            
                            var formatter = new Intl.NumberFormat(config.locale, {
                                style: 'currency',
                                currency: currency,
                                maximumFractionDigits: 0,
                                currencyDisplay: 'code',
                            });
                            this.campaigns[i][numbers[j].label] = formatter.format(value);
                        } else {
                            this.campaigns[i][numbers[j].label] = 0;
                        }
                    }
                }
            },
        }
    },
    methods: {
        toggleMode(darkMode) {
            let docClasses = document.body.classList;
            if (darkMode) {
                docClasses.remove('white-content');
                docClasses.add('dark-content');
            } else {
                docClasses.add('white-content');
                docClasses.remove('dark-content');
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        getError(fieldName) {
            return this.errors.first(fieldName)
        },
        getDataModels(force) {            
            this.$store.dispatch("getDataModels", [this.axios, force]);
        },
        getUserContent(force) {            
            this.$store.dispatch("getUserContent", [this.axios, force]);
        },
        getClients(force) {            
            this.$store.dispatch("getClients", [this.axios, force]);
        },
        handleRequestError(error) {
            console.error(error);
        },
        isCurrentRoute(routePath) {
            return this.$router.currentRoute.path == routePath;
        },
        loginSuccess(userEmail, userName, userID, companyName) {
            if((this.sydbankDomain || companyName !== null && companyName !== undefined && companyName === "Sydbank") && userID !== null && userID !== undefined){
                location.replace('https://api-pro.adplenty.io/plogin?pid=b451dd87-8299-4a72-8f89-0131d4751440&lang=da&accountId=' + userID);
            }
            else{
                this.axios.defaults.headers.common['Authorization'] = `Bearer: ${localStorage.authToken}`;
                this.authToken = localStorage.authToken;
                
                this.getUserContent(true);
                this.authenticated = true;

                this.user = userEmail;
                this.username = userName;
                this.userID = userID;

                if (this.$route.path == '/resetPassword') {
                    this.$router.replace('/');
                } 

                this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Successfully logged in",
                    showConfirmButton: false,
                    timer: 1500
                })

                this.authModalVisible = false;
            }
            
        },
        logout() {
            delete this.axios.defaults.headers.common["Authorization"];
            localStorage.removeItem('authToken');
            this.authenticated = false;
            this.authModalVisible = true;
            this.authToken = null;
            this.$store.dispatch('resetStore');
            this.redirectIfNotCurrent('/home');
        },
        showLoginLoading() {
            this.$swal({
                title: 'Logging in',
                text: 'Attempting to login user ' + this.username,
                timer: 1500,
                showConfirmButton: false
            });
        },
        redirectIfNotCurrent(routePath) {
            if (!this.isCurrentRoute(routePath)) {
                this.$router.push(routePath);
            }
        },
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        compareClients(a, b){
            if ( a.client < b.client ){
                return -1;
            }
            if ( a.client > b.client ){
                return 1;
            }
            return 0;
        },
        enableLoader(){
            this.loaderEnabled = true;
        },
        disableLoader(){
            this.loaderEnabled = false;
        },
        ...mapActions([
            'clearCampaignName',
            'clearDetailName',
            'updateSelectedClient'
        ]),
    },
    created() {
        let domain = window.location.hostname;
        if(domain.indexOf("sydbank") >= 0){
            this.sydbankDomain = true;
            this.authModalVisible = true;
        }

        this.username = localStorage.username;

        //api version will be defined in path constants
        this.axios.defaults.baseURL = config.apiUrl;

        const that = this;
               
        this.axios.interceptors.request.use(
            conf => {
                if (!that.loadingBlacklist.includes(conf.url.split((config.apiUrl))[0])) {
                    if(that.loaderEnabled){
                        that.loadingItems += 1;
                    }
                }
                return conf;
            },
            error => {
                if(that.loadingItems > 0 && that.loaderEnabled){
                    that.loadingItems -= 1;
                }
                return Promise.reject(error);
            }
        );

        this.axios.interceptors.response.use(
            function (response) {
                if(that.loadingItems > 0 && that.loaderEnabled){
                    that.loadingItems -= 1;
                }
                return response;
            },
            function (error) {
                if(that.loadingItems > 0 && that.loaderEnabled){
                    that.loadingItems -= 1;
                }
                if (error.response) {
                    if ((error.response.status === 403 || error.response.status === 401) && that.authenticated) {
                        console.warn("logging out")
                        that.logout();
                    }
                }
                return Promise.reject(error);
            }
        );
        if(this.sydbankDomain){
            this.$router.beforeEach((to, from) => {
                this.authModalVisible = true;
                return false;
            })
        }
        this.$router.afterEach((to, from) => {
            if(to.params.clientID && to.params.marathonCountryID){
                that.updateSelectedClient({client:{
                    clientID: to.params.clientID,
                    marathonCountryID: to.params.marathonCountryID
                }, axios: this.axios});
            }
            else{
                that.updateSelectedClient();
            }

            let root = false;
            Object.keys(this.$constants.routeNames).forEach((routeKey) => {
                if(this.$constants.routeNames[routeKey] && this.$constants.routeNames[routeKey].root){
                    root = root || this.$constants.routeNames[routeKey].root === to.name;
                }
            });
            if(root){
                this.clearCampaignName();
                this.clearDetailName();
            }
            this.isRootRoute = root;
        })
        if (this.$route.path == '/newUser') {
            // this.newUserModalVisible = true;
        } else {
            if (localStorage.authToken) {
                this.axios.defaults.headers.common['Authorization'] = `Bearer: ${localStorage.authToken}`;
                this.authToken = localStorage.authToken;
                this.authenticated = true;
                // WILL BE CALLED automatically by watcher
                this.getUserContent(true);
                this.getDataModels(true);
                this.getClients(true);
            } else {
                this.authModalVisible = true;
            }
        }

    },
    computed: {
        ...mapState([
            'clients',
            'campaignName',
            'detailName',
            'dataModels',
            'userContent',
            'userType',
            'isLoadingUserContent',
            'userSelectedMultipleClients',
            'selectedClient',
            'userSelectedCountry',
        ]),
        ...mapGetters([
            'internalUser',
            'userSelectedClient'
        ]),
        // currency(){
        //     if(this.$route.params.marathonCountryID){
        //         return this.$route.params.marathonCountryID.split("M")[1] + 'K';
        //     }
        //     return 'DKK';
        // },
        menuItems(){
            if(this.userContent){
                var resourceArray = new Array(Object.keys(this.userContent).length)
                for (var key in this.userContent) {
                    resourceArray[this.userContent[key].order] = this.userContent[key];
                }
                return resourceArray;
            }
            return [];
            
        },
        clientSelectOptions(){
            if (this.clients) {
                var clientSelectOptions = [];
               
                for (const client of this.clients) {
                    //var client = this.clients[i];
                    client.value = client.clientID
                    if (client.client.indexOf("Nordisk Film ") >= 0 && !this.internalUser) {
                        client.label = client.client.replace('Nordisk Film ', '');
                    } else {
                        client.label = client.client;
                    }
                    client.label += " - " + client.marathonCountryID.substring(2,4);
                    clientSelectOptions.push(client);
                }
                
                return clientSelectOptions.sort(this.compareClients);
            }
            else{
                return []
            }
        },
        countriesOptions(){
            return [{
                label: 'All',
                value: null,
                image: "/img/countries/globe.svg",
                currency: null
            },
            {
                label: 'Sweden',
                value: 'PMSE',
                image: "/img/countries/sweden.svg",
                currency: 'SEK',
            },
            {
                label: 'Norway',
                value: 'PMNO',
                image: "/img/countries/norway.svg",
                currency: 'NOK',
            },
            {
                label: 'Denmark',
                value: 'PMDK',
                image: "/img/countries/denmark.svg",
                currency: 'DKK',
            }
            ];
        },
    },
    watch: {
        darkMode:{
            immediate: true,
            handler(newVal){
                this.toggleMode(newVal);
            }
        },
        authToken(newToken) {
            if (newToken) {
                localStorage.authToken = newToken;
                this.authenticated = true;
                this.getUserContent(true);
                this.getDataModels(true);
                this.getClients(true);
            } else {
                this.authenticated = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.page-title_action{
    display: inline-block;
}
</style>
