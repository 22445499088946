<template>
  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <li class="sidebar-toggler">
          <sidebar-toggle-button />
        </li>
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>

import { CollapseTransition } from 'vue2-transitions';
import SidebarToggleButton from '@/pages/Layout/SidebarToggleButton';
export default {
    name: 'sidebar',
    components: {
        SidebarToggleButton,
        CollapseTransition,
    },
    props: {
        title: {
            type: String,
            default: 'Publicis Connect',
            description: 'Sidebar title'
        },
        shortTitle: {
            type: String,
            default: 'PC',
            description: 'Sidebar short title'
        },
        backgroundColor: {
            type: String,
            default: 'vue',
            validator: value => {
                let acceptedValues = [
                    '',
                    'vue',
                    'blue',
                    'green',
                    'orange',
                    'red',
                    'primary'
                ];
                return acceptedValues.indexOf(value) !== -1;
            },
            description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
        },
        sidebarLinks: {
            type: Array,
            default: () => [],
            description:
        "Static array of sidebar links, to override dynamic generation of sidebar links"
        },
        autoClose: {
            type: Boolean,
            default: true,
            description:
        'Whether sidebar should autoclose on mobile when clicking an item'
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        }
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    }
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

/* .sidebar {
    background: linear-gradient(0deg, rgb(186, 84, 245) 0%, rgb(157, 131, 62) 100%);
} */
</style>
