<template>
  <el-tooltip
    content="Sidebar toggle"
    effect="dark"
    :open-delay="300"
    placement="right"
  >
    <button
      class="minimize-sidebar btn btn-link btn-just-icon"
      @click="minimizeSidebar"
      rel="tooltip"
      data-original-title="Expand/Collapse menu"
      data-placement="right"
    >
      <i v-bind:class="[{ 'icon-connect-rotate-180': this.$sidebar.isMinimized }, 'icon-connect icon-connect-collapse']"></i>
    </button>
  </el-tooltip>
</template>
<script>


export default {
    name: 'sidebar-toggle-button',
    methods: {
        minimizeSidebar() {
            this.$sidebar.toggleMinimize();
        }
    }
};
</script>
<style></style>
