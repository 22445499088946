<template>
    <div class="white-content auth-modal login-modal" :class="sydbankVisual ? 'sydbank': ''" v-if="authModalVisible">
        <div class="row no-margin h-100">
            <div class="col-lg-5 bg-white">
                <div class="page-title">
                    <div class="row" v-if="!sydbankVisual">
                        <div class="col-2">
                            <img class="page-title__logo" src="/img/logo.png" />
                        </div>
                        <div class="col-8" v-if="!sydbankVisual"> 
                            <h1 class="page-title__name">
                                Publicis Connect
                            </h1>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-6">
                            <!-- <img class="page-title__logo" src="/img/logo_sydbank.png" /> -->
                            <img class="page-title__logo" src="/img/logo_sydbank.svg" />
                        </div>
                    </div>
                </div>
                <div class="secondary-page-title" v-if="sydbankVisual">
                    <h1>{{ $t('localMarketingPlatform') }}</h1>
                </div>
                <div class="auth-form-wrapper">
                    <div v-if="!resetingPassword">
                        <span class="auth-header">
                            <h1>{{ formName }}</h1>
                        </span>
                        <form @submit.prevent class="auth-form" v-if="!forgotPassword">
                            <div>
                                <form-input :label="$t('email')" :required="true" v-model="email" v-on:keyup.enter="userAuthentication" type="email" />
                                <form-input :label="$t('password')" :required="true" v-model="password" v-on:keyup.enter="userAuthentication" type="password" />
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-between"> -->
                                    <!--     <base-button class="" type="primary" size="md-width"  v-on:click="userAuthentication">Login</base-button> -->
                                    <!--     <base-button link class="pe-0 text-right" v-on:click="toggleForgotPasswordScreen">{{ $t('forgotPassword') }}</base-button> -->
                                    <!-- </div> -->
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <base-button class="btn btn-info" v-on:click="userAuthentication">Login</base-button>
                                    </div>
                                    <div class="col-6 text-right">
                                        <a class="link" v-on:click="toggleForgotPasswordScreen">{{ $t('forgotPassword') }}</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form @submit.prevent class="auth-form" v-if="forgotPassword && !emailSent" >
                            <form-input :label="$t('enterYourEmail')"  :required="true" v-model="email" v-on:keyup.enter="userAuthentication" type="text" />
                            <!-- <div class="d-grid gap-2 d-md-flex justify-content-between"> -->
                            <!--     <base-button class="me-auto" type="primary" size="md-width" v-on:click="sendForgotPasswordRequest">Send</base-button> -->
                            <!--     <base-button link class="pe-0 text-right" v-on:click="toggleForgotPasswordScreen">{{ $t('backToLogin') }}</base-button> -->
                            <!-- </div> -->
                            <div class="row justify-content-between">
                                <div class="col-6">
                                    <base-button class="btn btn-info" v-on:click="sendForgotPasswordRequest">
                                        Send</base-button>
                                </div>
                                <div class="col-6 text-right">
                                    <a class="link" v-on:click="toggleForgotPasswordScreen">{{ $t('backToLogin') }}</a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-else>
                        <span class="auth-header">
                            <h1>{{ formName }}</h1>
                        </span>
                        <form @submit.prevent class="auth-form" v-if="resetingPassword && !tokenExpired">
                            <div class="row">
                                <div class="col-12">
                                    <el-tooltip  manual v-model="passwordNotStrong" content="Password does not meet requirements" placement="bottom">
                                        <TextInput
                                            :required="true"
                                            v-model="newPassword"
                                            v-on:focus="resetFieldsTooltips"
                                            type="password"
                                            label="New password"
                                        />
                                    </el-tooltip>

                                    <el-tooltip manual v-model="passwordsNotSame" content="Password does not match" placement="bottom">
                                    <TextInput
                                            :required="true"
                                            v-model="newPasswordCheck"
                                            :isValid="passwordsSameCheck"
                                            v-on:focus="resetFieldsTooltips"
                                            type="password"
                                            label="Repeat new password"
                                        />
                                    </el-tooltip>
                                    <p class="password-note text-black">
                                        Password have to meet following requirements:
                                        <ul>
                                            <li>Minimum 8 characters</li>
                                            <li>1 Uppercase letter</li>
                                            <li>1 number</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-5">
                                    <base-button class="btn btn-info" v-on:click="sendPassword">Reset</base-button>
                                </div>
                            </div>
                        </form>
                        <form v-else>
                            <div class="row">
                                <div class="col-4">
                                    <base-button class="btn btn-info" v-on:click="sendForgotPasswordRequest">
                                        Send</base-button>
                                </div>
                                <div class="col-12 text-right">
                                    <a class="link" @click="reset">◀ Go to login</a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="note-wrapper">
                        <span class="action-note" >
                                {{ actionNote }}
                            <span>
                                <base-button is="a" class="" href="mailto:pmdkapi@publicismedia.com">{{ $t('contactUsHere') }}</base-button>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 bg-lion d-none d-lg-block">

            </div>
        </div>
    </div>

</template>

<script>
import TextInput from "@/components/Inputs/TextInput.vue";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
dayjs.extend(duration)
export default {
    data () {
        return {
            email: '',
            password: '',
            newPassword: '',
            newPasswordCheck: '',
            errorMsg: '',
            forgotPassword: false,
            resetingPassword: false,
            userID: null,
            tokenExpired: false,
            emailSent: false,
            passwordChanged: false,
            passwordNotStrong: false,
            passwordsNotSame: false,
        }
    },
    props: ['authModalVisible', 'sydbankVisual'],
    created() {
        if (this.sydbankVisual) this.$i18n.locale = 'da';
    },
    computed:{
        usernameValid(){
            if(this.email){
                return true;
            }
            return false;
        },
        passwordValid(){
            if(this.password){
                return true;
            }
            return false;
        },
        formName(){
            if(this.resetingPassword){
                if(this.tokenExpired){
                    return 'Your token has expired.'
                }
                if(this.passwordChanged){
                    return 'Your password is now changed'
                }
                return 'Reset password'
            }
            else{
                if(this.emailSent){
                    return this.$t('passwordResetSent');
                }
                if(this.forgotPassword){
                    return this.$t('forgotPassword');
                }
                return this.$t('login');
            }
        },
        actionNote(){
            if(this.emailSent || this.resetingPassword){
                return this.$t('needHelp');
            }
            return this.$t('dontHaveAccount');
        },
        passwordStrong(){
            let passwordRequirements = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
            return passwordRequirements.test(this.newPassword);
        },
        passwordsSameCheck(){
            const first = this.newPassword;
            const second = this.newPasswordCheck;
            return first === second;
        },
        swalUnknownError(){
            return {
                title: this.$t('loginFailed'),
                // html: 'The connect service returned a system error, or failed to return a response at all.<br>',
                html: this.$t('unknownError'),
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: this.$t('okThx'),
                confirmButtonAriaLabel: 'Close window',
                confirmButtonColor: '#00b7a6',
                cancelButtonColor: 'transparent',
                cancelButtonText: '<a class="contact-link" href="mailto:pmdkapi@publicismedia.com">Contact us</a>',
                cancelButtonAriaLabel: 'Contact us',
                customClass: "swal2-popup-light min-500",
            }
        },
    },
    methods: {
        swalUnsuccessful(message, code, attempts){
            return {
                title: this.$t('loginFailed'),
                // html: message,
                html: this.$t(message, { attempts: attempts }),
                showCloseButton: true,
                showCancelButton: code !== 403,
                focusConfirm: false,
                confirmButtonText: this.$t('okThx'),
                confirmButtonAriaLabel: this.$t('okThx'),
                confirmButtonColor: '#00b7a6',
                cancelButtonColor: 'transparent',
                cancelButtonText: `<span style="color:#00b7a6;">${ this.$t('resetPassword') }</span>`,
                cancelButtonAriaLabel: 'Reset password',
                customClass: "swal2-popup-light min-500",
            }
        },
        reset(){
            this.forgotPassword = false;
            this.resetingPassword = false;
            this.emailSent = false;
            this.passwordChanged = false;
        },
        checkTokenValidity() {
            const token = this.$route.query.token;
            const path = this.$constants.apiPaths.user.resetPassword;
            this.axios.get(path, {
                headers: {
                    Authorization: `Bearer: ${token}`
                }
            })
                .then(() => {
                    this.tokenExpired = false;
                })
                .catch(() => {
                    this.tokenExpired = true
                });
        },
        resetFieldsTooltips(){
            this.passwordNotStrong = false;
            this.passwordsNotSame = false;
        },
        sendPassword() {
            if (!this.passwordsSameCheck) {
                this.passwordsNotSame = true;
            }
            else if (!this.passwordStrong) {
                this.passwordNotStrong = true;
            }
             
            else{
                const token = this.$route.query.token;
                //console.log("RESET PASSWORD WITH TOKEN: \n" + token);
                const path = this.$constants.apiPaths.user.resetPassword;
                const payload = {
                    'newPassword': this.newPassword
                }                
                this.axios.put(path, payload, {
                    headers: {
                        Authorization: `Bearer: ${token}`
                    }
                })
                    .then(() => {
                        this.$swal.fire({
                            title: 'Password changed',
                            text:   'Proceed to login',
                            showCloseButton: true,
                            focusConfirm: false,
                            confirmButtonText: this.$t('okThx'),
                            confirmButtonAriaLabel: this.$t('okThx'),
                            confirmButtonColor: '#00b7a6',
                            customClass: "swal2-popup-light",
                            timer: 3000,
                        }).then(() => {
                            this.$router.replace('/');
                        })
                        this.passwordChanged = true;
                        this.resetingPassword = false;
                    })
                    .catch((error) => {
                        console.warn("Failed to reset password")
                        console.error(error);
                    });
            }
        },
        toggleForgotPasswordScreen() {
            this.forgotPassword = !this.forgotPassword;
            this.emailSent = false;
        },
        sendForgotPasswordRequest() {
            if(this.email){
                const user = { email: this.email };
                const url = this.$constants.apiPaths.emails.forgotPassword;
                const params = { email: user.email };
                this.axios.post(url, user, params).then(() => {
                    this.emailSent = true;
                })
                    .catch((error) => {
                        console.warn("Failed to post forgot password request")
                        console.error(error);
                        this.emailSent = true;
                    });
            }
            
        },
        userAuthentication() {
            if(this.email && this.password){
                const user = { email: this.email, password: this.password };
                const url = this.$constants.apiPaths.login;
                this.axios.post(url, user).then((result) => {
                    this.authToken = result.data.auth_token;
                    this.name = result.data.name;
                    this.userID = result.data.userID;
                    this.companyName = result.data.companyName;
                    localStorage.authToken = this.authToken;
                    localStorage.username = this.name;
                    localStorage.currentUserEmail = this.email;
                    localStorage.companyName = result.data.companyName;
                    localStorage.userID = result.data.userID;
                    this.$emit('loginSuccess', this.email, this.name, this.userID, this.companyName);
                })
                    .catch((error) => {
                        console.error("Failed to complete login request with error " + error)
                        var that = this;
                        if(error.response.data && error.response.data.message){
                            const message = error.response.data.message;
                            const attempts = error.response.data.attempts;
                            if(error.response.data.time){
                                let timerInterval
                                const now = dayjs.utc();
                                const close = dayjs.utc(error.response.data.time, 'YYYY-MM-DD HH:mm:ss');
                                const result = close.diff(now);
                                this.$swal.fire({
                                    title: this.$t('loginFailed'),
                                    // html: message,
                                    html: this.$t(message, { attempts: attempts }),
                                    timer: result,
                                    showCloseButton: true,
                                    focusConfirm: false,
                                    confirmButtonText: this.$t('okThx'),
                                    confirmButtonAriaLabel: this.$t('okThx'),
                                    confirmButtonColor: '#00b7a6',
                                    // cancelButtonColor: 'transparent',
                                    // cancelButtonText: '<span style="color:#00b7a6;">Reset password</span>',
                                    // cancelButtonAriaLabel: 'Reset password',
                                    customClass: "swal2-popup-light min-500",
                                    showCancelButton: false,
                                    didOpen: () => {
                                        const fillTime = this.$swal.getHtmlContainer().querySelector('strong')
                                        timerInterval = setInterval(() => {
                                            fillTime.textContent = dayjs.duration(this.$swal.getTimerLeft()).format('mm[m] ss[s]')
                                        }, 100)
                                    },
                                    willClose: () => {
                                        clearInterval(timerInterval)
                                    }
                                })
                            }
                            else{
                                this.$swal.fire(
                                    this.swalUnsuccessful(message, error.response.status, attempts)
                                ).then((result) => {
                                    if (result.dismiss === 'cancel') {
                                        that.toggleForgotPasswordScreen();
                                    }
                                })
                            }
                        // if(error.response.data && error.response.data.message && error.response.data.message != "" && error.response.data.time){
                            
                        // }
                        // else if(error.response.data && error.response.data.message && error.response.data.message != "" && error.response.data.time){
                        //     this.$swal.fire(
                        //         this.swalUnsuccessful(error.response.data.message, error.response.data.time, this.$swal)
                        //     ).then((result) => {
                        //         //console.log(result);
                        //         if (result.dismiss === 'cancel') {
                        //             that.toggleForgotPasswordScreen();
                        //         }
                        //     })
                        }
                        else{
                            this.$swal.fire(this.swalUnknownError)
                        }
                    });
            }
            
        },
        // connectStatus() {
        //     const url = config.apiUrl + '/status';
        //     this.axios.get(url, { headers: { Authorization: `${this.authToken}` } }.then(null, error => console.log(error.response.status)))
        // },

    },
    mounted(){
        if (this.$route.path == '/resetPassword') {
            this.resetingPassword = true;
            this.checkTokenValidity();
        } 
    },
    components: {
        TextInput,
    },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/sections/_auth_modal.scss";
</style>
<style lang="scss">
    @font-face {
        font-family: 'sydbank_sans';
        src: url('../../assets/fonts/sydbanksans-bold-webfont.woff2') format('woff2'),
            url('../../assets/fonts/sydbanksans-bold-webfont.woff') format('woff');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'sydbank_sans';
        src: url('../../assets/fonts/sydbanksans-regular-webfont.woff2') format('woff2'),
            url('../../assets/fonts/sydbanksans-regular-webfont.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }

    .auth-modal.sydbank{
        $graySydbank: #031E2F;
        font-family: 'sydbank_sans';

        .bg-lion{
            background-image: url('/img/login_background_sydbank.jpg');
            background-position: center left;
        }
        
        .page-title{
            &__logo{
                min-width: 125px;
            }
        }

        .secondary-page-title{
            margin: 0;
            position: absolute;
            top: 22%;
            left: 15%;

            h1{
                color: $graySydbank;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 75px;
            }
        }

        .auth-form-wrapper{
            .auth-form{
                label.label-input {
                    border: none;
                    border-bottom: 1px solid $graySydbank;
                    font-family: 'sydbank_sans';
                    border-radius: 0;
                    padding-left: 0;

                    & > span{
                        font-family: 'sydbank_sans';
                        color: $graySydbank;
                        padding-left: 0;
                    }

                    & > input{
                        padding-left: 0;
                    }
                }

                .btn-info{
                    margin-top: 25px;
                    background-color: $graySydbank;
                    background: $graySydbank;
                    min-width: 155px;
                    border-radius: 50px;
                    font-family: 'sydbank_sans';
                }
            }

            .action-note{
                font-weight: 400;
            }
                
            .link{
                color: #689AAC;
                font-weight: 400;
        
                &:hover{
                    color: darken(#689AAC, 10%);
                }
            }
        }
    }
</style>

<i18n>
{
    "en": {
        "localMarketingPlatform": "Local Marketing Platform",
        "login": "Log in",
        "email": "Email",
        "password": "Password",
        "forgotPassword": "Forgot password?",
        "dontHaveAccount": "Don’t have an account?",
        "contactUsHere": "Contact us here",

        "resetPassword": "Reset password",
        "okThx": "Okay, thanks!",
        "enterYourEmail": "Enter your email",
        "backToLogin": "◀ Back to login",
        "passwordResetSent": "A password reset email has been sent if such an account exists.",
        "needHelp": "Need help?",
        "loginFailed": "Login failed",

        "unknownError": "The connect service returned a system error, or failed to return a response at all.<br>",
        "accountBlockedUnblock": "<b>Your account has been blocked.</b><br><a href='mailto:pmdkapi@publicismedia.com' class='link contact-link'>Contact us</a> to unblock.<br>",
        "accountBlocked": "Your account is blocked for <strong></strong>, try again later.",
        "loginInvalid": "<b>Sorry, your email or password is incorrect.</b><br>Please try again, or reset your password.<br>Remaining attempts: {attempts}.<br>"
    },

    "da": {
        "localMarketingPlatform": "Lokal Marketing Platform",
        "login": "Log ind",
        "email": "Email",
        "password": "Adgangskode",
        "forgotPassword": "Glemt adgangskode?",
        "dontHaveAccount": "Har du ingen bruger?",
        "contactUsHere": "Kontakt os her",

        "resetPassword": "Nustil din adgangskode",
        "okThx": "Okay, tak",
        "enterYourEmail": "Skriv din email",
        "backToLogin": "◀ Tilbage til log ind",
        "passwordResetSent": "Vi har nulstillet din adgangskode. Tjek din email for at oprette en ny adgangskode.",
        "needHelp": "Har du brug for hjælp?",
        "loginFailed": "Noget gik galt. Prøv igen.",

        "unknownError": "Der er opstået en fejl. Prøv igen eller kontakt os.<br>",
        "accountBlockedUnblock": "<b>Din bruger er blevet låst.</b><br><a href='mailto:pmdkapi@publicismedia.com' class='link contact-link'>Kontakt os</a> for at låse din bruger op.<br>",
        "accountBlocked": "Din bruger er blevet blokeret i <strong></strong>. Prøv igen senere.",
        "loginInvalid": "<b>Din email eller adgangskode er forkert.</b><br>Prøv igen eller nulstil din adgangskode.<br>Du har {attempts} forsøg tilbage.<br>"
    }
}
</i18n>
